<template>
  <div>
    <checklist-add-new
      :checklist-main-id.sync="checklistMainId"
      :is-add-new-checklist-sidebar-active.sync="isAddNewChecklistSidebarActive"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Checklist Title"
                />
              </b-input-group>
              <b-button
                v-if="$can('checklist_create')"
                variant="primary"
                :to="{ name: 'add-checklist' }"
              >
                <span class="text-nowrap">Add Checklist</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchChecklist"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'employees', params: { id: data.item.hashid } }"
              class="font-weight-bold d-inline-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon
            @click="editRecord(data)"
            v-if="$can('checklist_edit')"
            v-b-tooltip.hover
            title="Edit"
            icon="EditIcon"
            size="16"
            class="mr-1 cursor-pointer"
          />

          <feather-icon
            @click="addChecklistNew(true, data)"
            v-if="$can('checklist_create')"
            v-b-tooltip.hover
            title="Add Checklist Item"
            icon="FolderPlusIcon"
            size="16"
            class="cursor-pointer"
          />

          <b-dropdown
            variant="link"
            size="sm"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item
              class="btn-sm p-0"
              @click="confirmDeleteRecord(data)"
              v-if="$can('checklist_delete')"
            >
              <feather-icon icon="TrashIcon" size="13" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalChecklist"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BButtonToolbar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import employeeStoreModule from "../../employeeStoreModule";
import useCheckList from "./useCheckList";
import ChecklistAddNew from "./ChecklistAddNew.vue";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,

    vSelect,
    ChecklistAddNew,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BButtonToolbar,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-employees";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewChecklistSidebarActive = ref(false);
    const checklistMainId = ref("");

    const {
      fetchChecklist,
      tableColumns,
      perPage,
      currentPage,
      totalChecklist,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    } = useCheckList();

    return {
      // Sidebar
      isAddNewChecklistSidebarActive,
      checklistMainId,
      fetchChecklist,
      tableColumns,
      perPage,
      currentPage,
      totalChecklist,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    };
  },
  methods: {
    addChecklistNew(flag, data) {
      // window.addEventListener('resize', this.initTrHeight)
      this.isAddNewChecklistSidebarActive = flag;
      this.checklistMainId = data.item.hashid;
    },
    editRecord(data) {
      this.$router.push(`/edit/checklist/${data.item.hashid}`).catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.title}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-employees/removeChecklist", id)
        .then((res) => {
          if (res.data.success) {
            self.refetchData();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Employee Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Employee deleted successfully",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Employee Deletion failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
